import { gql, useQuery } from "@apollo/client";
import PersonOutlineOutlined from "@mui/icons-material/PersonOutlineOutlined";
import {
  Avatar,
  AvatarTypeMap,
  CircularProgress,
  SvgIconTypeMap,
  useTheme,
} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { getNameInitials } from "../util/strings";
import { GetImage, GetImageVariables } from "./__generated__/GetImage";

const getImageQuery = gql`
  query GetImage($image_id: uuid!) {
    getImage(image_id: $image_id) {
      url
    }
  }
`;

type Props = {
  imageUuid?: string | null;
  firstName: string;
  lastName: string;
  style?: OverridableComponent<AvatarTypeMap<{}, "div">> | {};
  iconStyle?: OverridableComponent<SvgIconTypeMap<{}, "svg">> | {};
  variant?: "circular" | "rounded" | "square";
};

export const UserAvatar = (props: Props) => {
  const response = useQuery<GetImage, GetImageVariables>(getImageQuery, {
    variables: { image_id: props.imageUuid },
    // TODO: we may want to tell apollo that getImages url return is the unique identifier - but this works for now
    fetchPolicy: "no-cache",
  });
  const theme = useTheme();

  if (response.loading) {
    return (
      <Avatar
        sx={{ backgroundColor: theme.palette.primary.light, ...props.style }}
        variant={props.variant}
      >
        <CircularProgress
          sx={{ color: "#FFFFFF", padding: theme.spacing(1) }}
        />
      </Avatar>
    );
  }

  if (props.imageUuid != null && response?.data?.getImage?.url != null) {
    return (
      <Avatar
        src={response.data.getImage.url}
        sx={props.style || undefined}
        variant={props.variant}
      />
    );
  }

  const initials =
    props.firstName.length > 0 || props.lastName.length > 0
      ? getNameInitials(props.firstName, props.lastName)
      : "";

  return (
    <Avatar
      sx={{ backgroundColor: theme.palette.primary.light, ...props.style }}
      variant={props.variant}
    >
      {initials.length > 0 ? (
        initials
      ) : (
        <PersonOutlineOutlined
          style={props.iconStyle ? props.iconStyle : undefined}
        />
      )}
    </Avatar>
  );
};
