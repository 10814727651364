import {
  Box,
  ButtonBase,
  CSSObject,
  Divider,
  IconButton,
  List,
  styled,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { Link, useLocation } from "@reach/router";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIconTypeMap,
} from "@mui/material";
import HomeIconOutlined from "@mui/icons-material/Home";
import TaskAltOutlined from "@mui/icons-material/TaskAlt";
// import GroupOutlined from "@mui/icons-material/Group";
import ShoppingCart from "@mui/icons-material/ShoppingCart";
import MenuOutlined from "@mui/icons-material/MenuOutlined";
import MenuOpenOutlined from "@mui/icons-material/MenuOpenOutlined";
// import EmojiEventsOutlined from "@mui/icons-material/EmojiEventsOutlined";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { useContext, useState } from "react";
import { OrganisationModal } from "./OrganisationModal";
import { TODO } from "../types/util";
import NewspaperOutlined from "@mui/icons-material/NewspaperOutlined";
import { UserAvatar } from "./UserAvatar";
import packageDotJson from "../../package.json";
import { PreferencesContext } from "./context/PreferencesContext";
import { MyOrganisationsApp_stable_manager_organisations } from "../__generated__/MyOrganisationsApp";

export const DRAWER_WIDTH_OPEN = 250;
export const DRAWER_WIDTH_CLOSED = 60;

const openedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_WIDTH_OPEN,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_WIDTH_CLOSED,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  // width: `calc(${theme.spacing(7)} + 1px)`,
  // [theme.breakpoints.up("sm")]: {
  //   width: `calc(${theme.spacing(9)} + 1px)`,
  // },
});

export const StyleDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  // width: DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export const Drawer = ({
  open,
  setOpen,
  organisations,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  organisations: Array<MyOrganisationsApp_stable_manager_organisations>;
}) => {
  const [organisationModalOpen, setOrganisationModalOpen] = useState(false);
  const preferencesContext = useContext(PreferencesContext);
  const selectedOrganisation = organisations.find(
    (organisation) =>
      organisation.id === preferencesContext?.selectedOrganisationId
  );
  const theme = useTheme();

  /**
   * TODO: we might want to have a popover on mobile so that we cant click in the background
   */
  return (
    <StyleDrawer
      variant="permanent"
      open={open}
      sx={{
        "& .MuiDrawer-paper": {
          backgroundColor: "#4f4f4f",
          borderRightColor: "white",
        },
        overflow: "hidden",
        [`${theme.breakpoints.only("xs")}`]: {
          position: "absolute",
          display: open ? "flex" : "none",
        },
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexShrink: 1,
          minHeight: 56,
          justifyContent: open ? "flex-end" : "center",
          padding: theme.spacing(0, 1),
          [`${theme.breakpoints.up("sm")}`]: {
            minHeight: 64,
          },
        }}
      >
        <IconButton onClick={() => setOpen(!open)}>
          {open ? (
            <MenuOpenOutlined sx={{ color: "white" }} />
          ) : (
            <MenuOutlined sx={{ color: "white" }} />
          )}
        </IconButton>
      </Box>

      <Divider sx={{ borderColor: "rgba(255,255,255, 0.5)" }} />

      <Box sx={{ paddingX: theme.spacing(2), paddingY: theme.spacing(1) }}>
        {selectedOrganisation != null && (
          <Tooltip title="Switch organisation" arrow placement="right">
            <ButtonBase
              sx={{
                width: "100%",
              }}
              onClick={() => {
                setOrganisationModalOpen(true);
              }}
            >
              <UserAvatar
                variant="rounded"
                imageUuid={selectedOrganisation?.profile_picture}
                firstName={selectedOrganisation?.name || ""}
                lastName=""
              />
              {open && (
                <Typography
                  noWrap
                  sx={{
                    color: "#FFFFFF",
                    flex: 1,
                    paddingLeft: theme.spacing(1),
                    textAlign: "left",
                  }}
                  variant="body1"
                  component="span"
                >
                  {selectedOrganisation?.name}
                </Typography>
              )}
            </ButtonBase>
          </Tooltip>
        )}
      </Box>

      <Divider sx={{ borderColor: "rgba(255,255,255, 0.5)" }} />

      <List
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          overflowY: "auto",
        }}
      >
        <DrawerLink
          to="/"
          name="Home"
          DrawerLinkIcon={HomeIconOutlined}
          drawerOpen={open}
          setDrawerOpen={setOpen}
        />
        <DrawerLink
          to="/yardupdates"
          name="Yard Updates"
          DrawerLinkIcon={NewspaperOutlined}
          drawerOpen={open}
          setDrawerOpen={setOpen}
        />
        <DrawerLink
          to="/tasks"
          name="Tasks"
          DrawerLinkIcon={TaskAltOutlined}
          drawerOpen={open}
          setDrawerOpen={setOpen}
        />
        <DrawerLink
          to="/marketplace"
          name="Marketplace"
          DrawerLinkIcon={ShoppingCart}
          drawerOpen={open}
          setDrawerOpen={setOpen}
        />
        {/* <DrawerLink
          to="/competitions"
          name="Competitions"
          DrawerLinkIcon={EmojiEventsOutlined}
          drawerOpen={open}
          setDrawerOpen={setOpen}
        />
        <DrawerLink
          to="/horses"
          name="Horses"
          DrawerLinkIcon={({ sx }: { sx: TODO }) => (
            <svg
              viewBox="0 0 122.88 109.03"
              width={24}
              height={24}
              fill={sx.color}
            >
              <g>
                <path d="M21.2,72.04c4.63,1.68,11.49-2.09,22-14.74c3.98,1.47,7.1,4.73,8.46,11.72c2.58,13.31-1.52,21.83-7.22,33.54 c-1.06,2.17-2.12,4.32-3.15,6.48h81.29l-0.25-0.2c5.55-39.24-31.98-58.51-4.56-46.4c-11.78-28.52-48.09-37.8-22.47-34.84 C82.12,15.55,65.5,8.89,41.87,13.1C36.85,5.77,24.84-9.86,28.52,8.86L22.5,3.49l-0.33,18.89C15.01,27.84,11.1,46.03,5.56,57.86 c-4.74,4.7-6.56,10.32-5.05,19.06C7.89,86.43,19.54,84.07,21.2,72.04L21.2,72.04z" />
              </g>
            </svg>
          )}
          drawerOpen={open}
          setDrawerOpen={setOpen}
        /> */}
      </List>

      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
          height: 50,
          paddingX: theme.spacing(2),
          paddingBottom: theme.spacing(2),
        }}
      >
        <img src={"/logo192.png"} width={28} height={28} alt="stable_manager" />
        {open && (
          <Typography
            variant="body2"
            sx={{
              marginLeft: theme.spacing(1),
            }}
          >
            <a
              href="https://www.stablemanager.tech"
              target="_blank"
              rel="noopener noreferrer"
              style={
                {
                  textDecoration: "none",
                  color: "#FFFFFF",
                  fontWeight: "normal",
                } as TODO
              }
            >
              www.stablemanager.tech
            </a>
          </Typography>
        )}
      </Box>

      {open && (
        <Typography
          variant="body2"
          sx={{
            color: "#FFFFFF",
            width: "100%",
            paddingX: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            textAlign: "right",
            fontStyle: "italic",
          }}
        >
          v{packageDotJson.version}
        </Typography>
      )}

      {organisationModalOpen && (
        <OrganisationModal
          open={organisationModalOpen}
          setOpen={setOrganisationModalOpen}
          organisations={organisations}
        />
      )}
    </StyleDrawer>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DrawerLink = ({
  to,
  name,
  DrawerLinkIcon,
  drawerOpen,
  setDrawerOpen,
}: {
  to: string;
  name: string;
  DrawerLinkIcon:
    | (OverridableComponent<SvgIconTypeMap> & { muiName: string })
    | TODO;
  drawerOpen: boolean;
  setDrawerOpen: (drawerOpen: boolean) => void;
}) => {
  const theme = useTheme();
  const location = useLocation();
  const isSmallScreenOrUp = useMediaQuery(theme.breakpoints.up("sm"), {
    noSsr: true,
  });

  /**
   * THOUGHT: TODO: maybe we should hide navbar on mobile altogether and have menu
   * icon in top for expanding.  At this point it should be over entire screen?
   */
  const color =
    location.pathname === to ? theme.palette.primary.main : "#FFFFFF";

  return (
    <Tooltip title={name} arrow placement="right">
      <Link
        to={to}
        style={{ textDecoration: "none" }}
        onClick={() => {
          return !isSmallScreenOrUp ? setDrawerOpen(false) : undefined;
        }}
      >
        <ListItem
          button
          key={name}
          sx={
            drawerOpen
              ? { height: 50 }
              : {
                  height: 50,
                  alignItems: "center",
                  paddingLeft: 0,
                  paddingRight: 0,
                }
          }
        >
          <ListItemIcon
            sx={drawerOpen ? undefined : { justifyContent: "center" }}
          >
            <DrawerLinkIcon sx={{ color }} />
          </ListItemIcon>
          {drawerOpen && <ListItemText primary={name} sx={{ color }} />}
        </ListItem>
      </Link>
    </Tooltip>
  );
};
