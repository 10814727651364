import { gql, useMutation } from "@apollo/client";
import { Box, Typography, useTheme } from "@mui/material";
import { Link, navigate, RouteComponentProps } from "@reach/router";
import { useSnackbar } from "notistack";
import { parse } from "query-string";
import { useContext, useEffect, useState } from "react";
import { PreferencesContext } from "../components/context/PreferencesContext";
import { LoadingFragment } from "../components/Loading";
import { sentry } from "../util/sentry";
import {
  CreateOrgStripeConnection,
  CreateOrgStripeConnectionVariables,
} from "./__generated__/CreateOrgStripeConnection";

const createOrgStripeConnectionMutation = gql`
  mutation CreateOrgStripeConnection($orgId: uuid!, $authCode: String!) {
    createOrgStripeConnection(
      organisation_id: $orgId
      stripe_connect_auth_code: $authCode
    ) {
      success
    }
  }
`;

const SubscriptionStripeConnect = (props: RouteComponentProps) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const preferencesContext = useContext(PreferencesContext);
  const [userErrorMessage, setUserErrorMessage] = useState<string | null>(null);
  const [createStripeConnection, createStripeConnectionResponse] = useMutation<
    CreateOrgStripeConnection,
    CreateOrgStripeConnectionVariables
  >(createOrgStripeConnectionMutation);
  const { code } = parse(props.location?.search as string);

  useEffect(() => {
    if (code != null && preferencesContext?.selectedOrganisationId != null) {
      createStripeConnection({
        variables: {
          orgId: preferencesContext?.selectedOrganisationId,
          authCode: code as string,
        },
      })
        .then((response) => {
          if (response.data?.createOrgStripeConnection?.success) {
            enqueueSnackbar("Successfully connected organisation to Stripe", {
              variant: "success",
            });

            navigate("/subscription");
          } else {
            setUserErrorMessage("Unable to connect organisation to Stripe");
            sentry.error(
              new Error(
                `createOrgStripeConnection returned an error for orgId: ${preferencesContext?.selectedOrganisationId}, code: ${code}`
              )
            );
          }
        })
        .catch((error) => {
          setUserErrorMessage("Unable to connect organisation to Stripe");
          sentry.error(
            new Error(
              `createOrgStripeConnection error caught for orgId: ${preferencesContext?.selectedOrganisationId}, code: ${code}`
            )
          );
        });
    } else {
      setUserErrorMessage(
        "Unable to connect organisation to Stripe - invalid parameters"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, preferencesContext?.selectedOrganisationId]);

  if (createStripeConnectionResponse.loading) {
    return <LoadingFragment />;
  }

  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        flex: 1,
        flexDirection: "column",
        height: theme.breakpoints.up("sm")
          ? "calc(100vh - 96px)"
          : "calc(100vh - 88px)",
        justifyContent: "center",
        maxHeight: theme.breakpoints.up("sm")
          ? "calc(100vh - 96px)"
          : "calc(100vh - 88px)",
        width: "100%",
      }}
    >
      {userErrorMessage != null && (
        <>
          <Typography
            variant="subtitle1"
            sx={{
              textAlign: "center",
              fontStyle: "italic",
            }}
          >
            {userErrorMessage}
          </Typography>

          <Link to="/subscription">
            <Typography variant="subtitle1" component="span">
              Return to subscription page
            </Typography>
          </Link>
        </>
      )}
    </Box>
  );
};

export default SubscriptionStripeConnect;
