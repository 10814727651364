import { useContext } from "react";
import {
  Modal,
  Box,
  Typography,
  useTheme,
  ButtonBase,
  Radio,
} from "@mui/material";
import { UserAvatar } from "./UserAvatar";
import { PreferencesContext } from "./context/PreferencesContext";
import { MyOrganisationsApp_stable_manager_organisations } from "../__generated__/MyOrganisationsApp";

export const OrganisationModal = ({
  open,
  setOpen,
  organisations,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  organisations: Array<MyOrganisationsApp_stable_manager_organisations>;
}) => {
  const theme = useTheme();
  const preferencesContext = useContext(PreferencesContext);

  /**
   * TODO: sidebar org link
   */
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          boxShadow: 24,
          left: "50%",
          padding: theme.spacing(4),
          position: "absolute",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Typography variant="h6" component="h2">
          Select an organisation
        </Typography>
        <Box sx={{ marginTop: theme.spacing(2) }}>
          {organisations.map((organisation, index) => (
            <Box
              key={organisation.id}
              sx={{ marginTop: index !== 0 ? theme.spacing(1) : 0 }}
            >
              <ButtonBase
                sx={{
                  width: "100%",
                  justifyContent: "flex-start",
                }}
                onClick={() => {
                  preferencesContext?.setSelectedOrganisationId(
                    organisation.id, true
                  );
                  setOpen(false);
                }}
              >
                <UserAvatar
                  variant="rounded"
                  imageUuid={organisation.profile_picture}
                  firstName={organisation.name || ""}
                  lastName=""
                />
                {open && (
                  <Typography
                    noWrap
                    sx={{
                      flex: 1,
                      paddingLeft: theme.spacing(1),
                      textAlign: "start",
                    }}
                    variant="body1"
                    component="span"
                  >
                    {organisation.name}
                  </Typography>
                )}
                <Radio
                  checked={
                    preferencesContext?.selectedOrganisationId ===
                    organisation.id
                  }
                />
              </ButtonBase>
            </Box>
          ))}
        </Box>
      </Box>
    </Modal>
  );
};
