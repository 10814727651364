import { Box, useTheme } from "@mui/material";
import { useEffect } from "react";
import { OutlinedButton } from "./Button";
import logo from "../assets/logo192.png";

export const ErrorScreen = ({
  error,
  resetError,
}: {
  error: Error;
  resetError: () => void;
}) => {
  const theme = useTheme();

  useEffect(() => {
    if (error?.constructor?.name.toLowerCase().includes("chunkloaderror")) {
      const timer = setTimeout(() => window.location.reload(), 1000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  if (error?.constructor?.name.toLowerCase().includes("chunkloaderror")) {
    return (
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "100vh",
          width: "100vw",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <img src={logo} style={{ height: 75, width: 75 }} alt="logo" />
        </Box>

        <p style={{ marginTop: theme.spacing(4), textAlign: "center" }}>
          A newer version of Stable Manager is available
        </p>
        <p style={{ marginTop: theme.spacing(2), textAlign: "center" }}>
          If your screen doesn't refresh automatically, please refresh now.
        </p>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginTop: theme.spacing(4),
            width: "100%",
          }}
        >
          <OutlinedButton
            onClick={() => window.location.reload()}
            color="primary"
          >
            Refresh
          </OutlinedButton>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "100vh",
        width: "100vw",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <img src={logo} style={{ height: 75, width: 75 }} alt="logo" />
      </Box>

      <p style={{ marginTop: theme.spacing(4), textAlign: "center" }}>
        There has been an error
      </p>
      <p style={{ marginTop: theme.spacing(2), textAlign: "center" }}>
        Our engineers have been notified and are looking in to it 👷
      </p>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          marginTop: theme.spacing(4),
          width: "100%",
        }}
      >
        <OutlinedButton onClick={resetError} color="primary">
          Return to screen
        </OutlinedButton>
      </Box>
    </Box>
  );
};
