import * as Sentry from "@sentry/react";
import { config } from "../config";

export const sentry = {
  init: () => {
    if (process.env.NODE_ENV === "development") {
      console.info("running sentry init", {
        dsn: "https://5dce42d856c94d7c9242fe5e1309c89f@o1023440.ingest.sentry.io/6051054",
        environment: "DEV",
      });
    } else {
      Sentry.init({
        dsn: "https://5dce42d856c94d7c9242fe5e1309c89f@o1023440.ingest.sentry.io/6051054",
        environment: process.env.REACT_APP_ENVIRONMENT,
      });
      Sentry.setTag("environment", process.env.REACT_APP_ENVIRONMENT);
      Sentry.setTag("version_code", config.versionCode)
    }
  },
  setUser: (id: string) => {
    if (process.env.NODE_ENV !== "development") {
      Sentry.setUser({ id });
    }
  },
  error: (error: Error) => {
    if (process.env.NODE_ENV === "development") {
      console.error(error);
    } else {
      Sentry.captureException(error);
    }
  },
  message: (message: string) => {
    if (process.env.NODE_ENV === "development") {
      console.info(`Sentry message: ${message}`);
    } else {
      Sentry.captureMessage(message);
    }
  },
};
