import { useContext, useState } from "react";
import { navigate, useLocation } from "@reach/router";
import {
  AppBar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { gql, useQuery } from "@apollo/client";
import { MyUserAppBar } from "./__generated__/MyUserAppBar";
import { routes } from "../config";
import MenuOutlined from "@mui/icons-material/MenuOutlined";
import { UserAvatar } from "./UserAvatar";
import { DRAWER_WIDTH_OPEN, DRAWER_WIDTH_CLOSED } from "./Drawer";
import { PreferencesContext } from "./context/PreferencesContext";

const getMyUser = gql`
  query MyUserAppBar($email: String!) {
    stable_manager_users(where: { email: { _eq: $email } }) {
      id
      address
      description
      email
      firstname
      lastname
      profile_picture
      phone_number
    }
  }
`;

/**
 * TODO:
 * --- week/month view
 * --- date picker
 */

export const TopAppBar = ({
  drawerOpen,
  setDrawerOpen,
}: {
  drawerOpen: boolean;
  setDrawerOpen: (drawerOpen: boolean) => void;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const theme = useTheme();
  const { logout, user } = useAuth0();
  const location = useLocation();
  const extraSmallScreen = useMediaQuery(theme.breakpoints.only("xs"));
  const response = useQuery<MyUserAppBar>(getMyUser, {
    variables: { email: user?.email! },
    skip: user?.email == null,
  });
  const preferencesContext = useContext(PreferencesContext);

  if (response.error) {
    throw new Error(`Failed to fetch user: ${response.error}`);
  }

  return (
    <AppBar
      position="fixed"
      sx={{
        width: "100vw",
        [theme.breakpoints.up("sm")]: {
          width: drawerOpen
            ? `calc(100vw - ${DRAWER_WIDTH_OPEN}px)`
            : `calc(100vw - ${DRAWER_WIDTH_CLOSED}px)`,
        },
      }}
    >
      <Toolbar>
        <Box sx={{ width: 56 }}>
          {extraSmallScreen && (
            <IconButton onClick={() => setDrawerOpen(true)}>
              <MenuOutlined sx={{ color: "#FFFFFF" }} />
            </IconButton>
          )}
        </Box>
        <Typography
          variant="h6"
          component="div"
          sx={{ flex: 1, color: "#FFFFFF", textAlign: "center" }}
        >
          {
            routes.find((route) => route.path === location.pathname)
              ?.description
          }
        </Typography>

        {response.data != null && (
          <>
            <Tooltip
              title={`${response.data?.stable_manager_users[0].firstname} ${response.data?.stable_manager_users[0].lastname}`}
            >
              <IconButton
                onClick={(event) => {
                  setAnchorEl(event.currentTarget);
                }}
              >
                <UserAvatar
                  imageUuid={
                    response.data?.stable_manager_users[0].profile_picture
                  }
                  firstName={
                    response.data?.stable_manager_users[0].firstname || ""
                  }
                  lastName={
                    response.data?.stable_manager_users[0].lastname || ""
                  }
                />
              </IconButton>
            </Tooltip>

            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => {
                setAnchorEl(null);
              }}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem
                onClick={() => {
                  // TODO: add clickaway so menu closes on mobile.
                  // this might only be in Dev though?...
                  navigate("/profile");
                  setAnchorEl(null);
                }}
              >
                My profile
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate("/settings");
                  setAnchorEl(null);
                }}
              >
                Settings
              </MenuItem>
              <MenuItem
                onClick={() => {
                  preferencesContext?.unsetAllPreferences()
                  logout({
                    returnTo: window.location.origin,
                  });
                }}
              >
                Logout
              </MenuItem>
            </Menu>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};
