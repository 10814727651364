export const getNameInitials = (firstName: string, lastName: string) => {
  return `${firstName.charAt(0).toUpperCase()}${lastName
    .charAt(0)
    .toUpperCase()}`;
};

export const uppercaseFirstLetter = (characters: string) => {
  return `${characters.charAt(0).toUpperCase()}${characters.slice(
    1,
    characters.length
  )}`;
};
