import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
// import reportWebVitals from "./reportWebVitals";
import "./fonts/Poppins/Poppins-Regular.ttf";
import { Auth0Provider } from "@auth0/auth0-react";

/**
 * TODO:
 * - icons specified in the manifest arent the right size, because
 * icon we get was not perfectly square.  We can squash it or get a new one?
 * - we might also want to add the various sized favicos
 */

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      redirectUri={window.location.origin}
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

/**
 * TODO: we are registering the service worker, but we may want to see how
 * the app behaves offline and do some testing before settling on this...
 */
serviceWorkerRegistration.unregister();
// serviceWorkerRegistration.register();

// TODO: we may want to also make use of this
// if (process.env.NODE_ENV === "development") {
//   reportWebVitals(console.log);
// }
