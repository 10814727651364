import { gql, useQuery } from "@apollo/client";
import { navigate } from "@reach/router";
import { createContext, ReactChild, useEffect, useState } from "react";
import { LoadingScreenFixed } from "../Loading";
import { MyOrganisations } from "./__generated__/MyOrganisations";

export const PreferencesContext = createContext<
  | undefined
  | {
      selectedOrganisationId: string | undefined;
      setSelectedOrganisationId: (id: string, navigateToOrgPage?: boolean) => void;
      unsetAllPreferences: () => void;
    }
>(undefined);

const getMyOrganisations = gql`
  query MyOrganisations {
    stable_manager_organisations {
      id
    }
  }
`;

export const PreferencesContextProvider = ({
  children,
}: {
  children: ReactChild;
}) => {
  const [selectedOrganisationId, setSelectedOrganisationId] = useState(
    localStorage.getItem("selectedOrganisationId")
  );
  const myOrganisationsResponse = useQuery<MyOrganisations>(getMyOrganisations);

  const updateSelectedOrganisation = (
    id: string,
    navigateToOrgPage?: boolean
  ) => {
    persistSelectedOrganisationId(id);

    if (navigateToOrgPage) {
      navigate("/organisation");
    }
  };

  const persistSelectedOrganisationId = (id: string) => {
    setSelectedOrganisationId(id);
    localStorage.setItem("selectedOrganisationId", id);
  };

  const unsetAllPreferences = () => {
    setSelectedOrganisationId(null);
    localStorage.removeItem("selectedOrganisationId");
  };

  useEffect(() => {
    /**
     * If we haven't selected an organisation yet, select the first one.
     *
     * If there is a selected organisation, but it's not one of our own - default
     * to one of our own.
     */
    if (
      myOrganisationsResponse.data != null &&
      selectedOrganisationId == null
    ) {
      persistSelectedOrganisationId(
        myOrganisationsResponse.data.stable_manager_organisations[0].id
      );
    } else if (
      myOrganisationsResponse.data != null &&
      selectedOrganisationId != null
    ) {
      const persistedOrganisation =
        myOrganisationsResponse.data?.stable_manager_organisations.find(
          (organisation) => organisation.id === selectedOrganisationId
        );

      if (persistedOrganisation == null) {
        persistSelectedOrganisationId(
          myOrganisationsResponse.data?.stable_manager_organisations[0].id
        );
      }
    }
  }, [myOrganisationsResponse.data, selectedOrganisationId]);

  if (myOrganisationsResponse.loading || selectedOrganisationId == null) {
    return <LoadingScreenFixed />;
  }

  if (myOrganisationsResponse.error || selectedOrganisationId == null) {
    throw new Error(
      `Failed to ${
        selectedOrganisationId == null ? "set" : "fetch"
      } organisation: ${myOrganisationsResponse.error}`
    );
  }

  return (
    <PreferencesContext.Provider
      value={{
        selectedOrganisationId,
        setSelectedOrganisationId: updateSelectedOrganisation,
        unsetAllPreferences,
      }}
    >
      {children}
    </PreferencesContext.Provider>
  );
};
