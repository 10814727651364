import { Button, ButtonProps, CircularProgress, useTheme } from "@mui/material";
import { TODO } from "../types/util";

export const OutlinedButton = (
  props: ButtonProps & { isLoading?: boolean; component?: "span" }
) => {
  const theme = useTheme();
  const filteredProps = { ...props };

  // Don't pass random props on to dom
  delete filteredProps.isLoading;

  let sx = {
    height: 40,
    textTransform: "none",
  };

  if (props.sx != null) {
    sx = { ...props.sx, ...sx };
  }

  return (
    <Button
      {...filteredProps}
      variant="outlined"
      sx={sx as TODO}
      disabled={props.disabled || props.isLoading}
      endIcon={
        props.isLoading ? (
          <CircularProgress color={props.color} size={theme.spacing(2)} />
        ) : undefined
      }
    >
      {props.children}
    </Button>
  );
};

export const ContainedButton = (
  props: ButtonProps & { isLoading?: boolean; wide?: boolean }
) => {
  const theme = useTheme();
  const filteredProps = { ...props };

  // Don't pass random props on to dom
  delete filteredProps.isLoading;

  let sx = {
    color: "#FFFFFF",
    height: 40,
    textTransform: "none",
  };

  if (props.sx != null) {
    sx = { ...props.sx, ...sx };
  }

  return (
    <Button
      {...filteredProps}
      variant="contained"
      sx={sx as TODO}
      disabled={props.disabled || props.isLoading}
      endIcon={
        props.isLoading ? (
          <CircularProgress sx={{ color: "#FFFFFF" }} size={theme.spacing(2)} />
        ) : undefined
      }
    >
      {props.children}
    </Button>
  );
};
