import { Box, CircularProgress, useMediaQuery, useTheme } from "@mui/material";
import Lottie from "react-lottie";
import greenHorseAnimation from "../assets/animations/GreenLottieHorseAnimation.json";

export const LoadingScreenFixed = () => {
  const theme = useTheme()
  const isSmallScreenOrUp = useMediaQuery(theme.breakpoints.up("sm"), {
    noSsr: true,
  });
  
  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#FFFFFF"
      }}
    >
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: greenHorseAnimation,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
        height={isSmallScreenOrUp ? 200 : 150}
        width={isSmallScreenOrUp ? 200 : 150}
      />
    </Box>
  );
};

export const LoadingFragment = () => {
  const theme = useTheme();
  const isSmallScreenOrUp = useMediaQuery(theme.breakpoints.up("sm"), {
    noSsr: true,
  });
  
  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        flex: 1,
        justifyContent: "center",
        height: theme.breakpoints.up("sm")
          ? "calc(100vh - 80px)"
          : "calc(100vh - 72px)",
      }}
    >
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: greenHorseAnimation,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
        height={isSmallScreenOrUp ? 200 : 150}
        width={isSmallScreenOrUp ? 200 : 150}
      />
    </Box>
  );
};

export const LoadingInline = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        flex: 1,
        height: "100%",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <CircularProgress size={theme.spacing(4)} />
    </Box>
  );
};
