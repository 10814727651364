import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  from,
  InMemoryCache,
} from "@apollo/client";

const cache = new InMemoryCache();

export const createApolloClient = (token: string): ApolloClient<unknown> => {
  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_API_ENDPOINT,
  });

  const authLink = new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return forward(operation);
  });

  /**
   * We might use defaultOptions to set the fetchPolicy to "cache-and-network" for
   * `watchQuery` (useQuery), but it seems that there is an issue with setting this on its
   * own without also setting defaultOptions for `query` and `mutation` also.
   *
   * I think we will do this at a query level for now...
   */
  return new ApolloClient({
    link: from([authLink, httpLink]),
    cache,
    // we will go with this fetch policy for now and see how it works...
    defaultOptions: { watchQuery: { fetchPolicy: "cache-and-network" } },
  });
};
