import { cloneElement, lazy, Suspense, useEffect, useState } from "react";
import { Router } from "@reach/router";
import { Box, createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { Drawer } from "./components/Drawer";
import { LoadingScreenFixed } from "./components/Loading";
import { TopAppBar } from "./components/TopAppBar";
import { createApolloClient } from "./ApolloClient";
import { ApolloClient, ApolloProvider, gql, useQuery } from "@apollo/client";
import { SnackbarProvider } from "notistack";
import * as Sentry from "@sentry/react";
import { sentry } from "./util/sentry";
import { ErrorScreen } from "./components/Error";
import { MyUser, MyUserVariables } from "./__generated__/MyUser";
import { TODO } from "./types/util";
import { PreferencesContextProvider } from "./components/context/PreferencesContext";
import { MyOrganisationsApp } from "./__generated__/MyOrganisationsApp";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import SubscriptionStripeConnect from "./pages/SubscriptionStripeConnect";
import CalendarConnectSuccess from "./pages/CalendarConnectSuccess";

// const Home = lazy(() => import("./pages/Home"));
const Settings = lazy(() => import("./pages/Settings"));
const Organisation = lazy(() => import("./pages/Organisation"));
const Profile = lazy(() => import("./pages/Profile"));
const HorseProfile = lazy(() => import("./pages/HorseProfile"));
const OrganisationUsers = lazy(() => import("./pages/OrganisationUsers"));
const Resource = lazy(() => import("./pages/Resource/Resource"));
const ResourceSetAvailability = lazy(
  () => import("./pages/Resource/SetAvailability")
);
const Subscription = lazy(() => import("./pages/Subscription"));
const CalendarConnect = lazy(() => import("./pages/CalendarConnect"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const EndUserLicence = lazy(() => import("./pages/EndUserLicence"));
const CookiePolicy = lazy(() => import("./pages/CookiePolicy"));
const YardUpdates = lazy(() => import("./pages/YardUpdates"));
const Tasks = lazy(() => import("./pages/Tasks"));
const Marketplace = lazy(() => import("./pages/Marketplace"));
const MarketplaceResource = lazy(() => import("./pages/MarketplaceResource"));
const MarketplaceResourceBookingType = lazy(
  () => import("./pages/MarketplaceResourceBookingType")
);
const MarketplaceMyBookings = lazy(
  () => import("./pages/MarketplaceMyBookings")
);

// const Competitions = lazy(() => import("./pages/Competitions"));
// const Horses = lazy(() => import("./pages/Horses"));
// const Team = lazy(() => import("./pages/Team"));

const theme = createTheme({
  palette: {
    primary: { main: "#97C319", light: "#B9DB4B", dark: "#7DA712" },
    secondary: { main: "#0F79FC", light: "#4AA2FD", dark: "#0A5DD8" },
  },
  typography: {
    fontFamily: "PoppinsRegular",
  },
});

const getMyUser = gql`
  query MyUser($email: String!) {
    stable_manager_users(where: { email: { _eq: $email } }) {
      id
    }
  }
`;

sentry.init();

const AppWithErrorBoundary = () => {
  return (
    <Sentry.ErrorBoundary
      fallback={({ resetError, error }) => (
        <ErrorScreen error={error} resetError={resetError} />
      )}
    >
      <AppWithAuth />
    </Sentry.ErrorBoundary>
  );
};

const AppWithAuth = () => {
  const { isLoading, error, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  useEffect(() => {
    if (error) {
      sentry.error(new Error(`Error with authentication: ${error}`));
    }
  }, [error]);

  if (isLoading) {
    return (
      <ThemeProvider theme={theme}>
        <LoadingScreenFixed />
      </ThemeProvider>
    );
  }

  if (error) {
    return (
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Failed to authenticate
        </Box>
      </ThemeProvider>
    );
  }

  return <App />;
};

const getMyOrganisations = gql`
  query MyOrganisationsApp {
    stable_manager_organisations {
      id
      name
      profile_picture
    }
  }
`;

const Main = ({ path, children }: { path: string; children: TODO }) => {
  const { user } = useAuth0();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const myUserResponse = useQuery<MyUser, MyUserVariables>(getMyUser, {
    variables: { email: user?.email! },
    skip: user?.email == null,
  });
  const myOrganisationsResponse =
    useQuery<MyOrganisationsApp>(getMyOrganisations);

  useEffect(() => {
    if (myUserResponse.data?.stable_manager_users[0]?.id != null) {
      sentry.setUser(myUserResponse.data?.stable_manager_users[0].id);
    }
  }, [myUserResponse.data]);

  if (myUserResponse.loading || myOrganisationsResponse.loading) {
    return <LoadingScreenFixed />;
  }

  if (myUserResponse.error) {
    throw new Error(`Failed to fetch user: ${myUserResponse.error}`);
  }

  if (myOrganisationsResponse.error) {
    throw new Error(
      `Failed to fetch organisation: ${myOrganisationsResponse.error}`
    );
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Drawer
        open={drawerOpen}
        setOpen={setDrawerOpen}
        organisations={
          myOrganisationsResponse.data?.stable_manager_organisations || []
        }
      />
      <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          width: "100%",
          overflow: "hidden",
        }}
      >
        <TopAppBar drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
        <Suspense
          fallback={
            <Box
              sx={{
                height: "100vh",
                width: "100vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenFixed />
            </Box>
          }
        >
          {cloneElement(children, {
            style: {
              display: "flex",
              padding: theme.spacing(2),
              // allow app bar to overlap page content
              paddingTop: theme.breakpoints.up("sm") ? "80px" : "72px",
              width: "100%",
            },
          })}
        </Suspense>
      </Box>
    </Box>
  );
};

const App = () => {
  const [client, setClient] = useState<null | ApolloClient<any>>(null);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (client == null) {
      getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE })
        .then(async (token) => {
          const client = await createApolloClient(token);
          setClient(client);
        })
        .catch((error) => {
          // Throwing an error making loading screen jumpy - will just send a message for now
          sentry.message(`Error with getAccessTokenSilently: ${error}`);
        });
    }
  }, [client, getAccessTokenSilently]);

  if (client == null) {
    return (
      <ThemeProvider theme={theme}>
        <LoadingScreenFixed />
      </ThemeProvider>
    );
  }

  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      <ApolloProvider client={client}>
        <PreferencesContextProvider>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Router>
                <Main path="/">
                  {/* <Home path="/" /> */}
                  <Settings path="/settings" />
                  <Organisation default path="/organisation" />
                  <Profile path="/profile" />
                  <Profile path="/profile/:id" />
                  <HorseProfile path="/horse/:id" />
                  <OrganisationUsers path="/organisationusers" />
                  <Resource path="/resource/:id" />
                  <ResourceSetAvailability path="/resource/:id/setavailability" />
                  <Subscription path="/subscription" />
                  <SubscriptionStripeConnect path="/subscription/stripeconnect" />
                  <CalendarConnect path="/calendarconnect" />
                  <CalendarConnectSuccess path="/calendarconnect/success" />
                  <PrivacyPolicy path="/privacypolicy" />
                  <EndUserLicence path="/enduserlicence" />
                  <CookiePolicy path="/cookiepolicy" />
                  <YardUpdates path="/yardupdates" />
                  <Tasks path="/tasks" />
                  <Marketplace path="/marketplace" />
                  <MarketplaceResource path="/marketplace/resource/:id" />
                  <MarketplaceResourceBookingType path="/marketplace/resourcebookingtype/:id" />
                  <MarketplaceMyBookings path="/marketplace/mybookings" />
                  {/* <Competitions path="/competitions" />
                <Horses path="/horses" /> */}
                </Main>
              </Router>
            </LocalizationProvider>
          </ThemeProvider>
        </PreferencesContextProvider>
      </ApolloProvider>
    </SnackbarProvider>
  );
};

export default AppWithErrorBoundary;
