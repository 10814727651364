export const config = {
  versionCode: "1.0.0"
}

export const routes = [
  { path: "/", description: "Organisation" }, // TODO: this will be a home screen at some point, but default to org for now
  { path: "/yardupdates", description: "Yard Updates" },
  { path: "/tasks", description: "Tasks" },
  { path: "/marketplace", description: "Marketplace" },
  { path: "/competitions", description: "Competitions" },
  { path: "/horses", description: "Horses" },
  { path: "/team", description: "Team" },
  { path: "/settings", description: "Settings" },
  { path: "/organisation", description: "Organisation" },
  { path: "/profile", description: "Profile" },
  { path: "/organisationusers", description: "Organisation Users" },
  { path: "/subscription", description: "Subscription" },
  { path: "/privacypolicy", description: "Privacy Policy" },
  { path: "/enduserlicence", description: "End User Licence" },
  { path: "/cookiepolicy", description: "Cookie Policy" },
];

export const colors = {
  "color-primary-100": "#F5FCD0",
  "color-primary-200": "#E9F9A2",
  "color-primary-300": "#D3EC71",
  "color-primary-400": "#B9DB4B",
  "color-primary-500": "#97C319",
  "color-primary-600": "#7DA712",
  "color-primary-700": "#648C0C",
  "color-primary-800": "#4D7107",
  "color-primary-900": "#3D5D04",
};
